// Customizable Area Start
import React, { Component } from 'react';
import { withStyles, Theme, createStyles, WithStyles,styled } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box,Button,Typography,Accordion,AccordionDetails,AccordionSummary } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
const emptyReward = require('./empty_reward.svg');
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const greenRoundCheck = require('./round_greencheck.svg')
const checkMark = require('./icon_check.svg')
const redeemCheck = require('./redeem_check.svg')
const roundUncheck = require('./round_uncheck.svg')
const unCheckMark = require('./icon_uncheck.svg')

const StyledProgressBar = styled(LinearProgress)({
    height: "8px !important",
    borderRadius: "5px",
    backgroundColor: "#EEE8F2 !important",
    "& .MuiLinearProgress-barColorPrimary":{
        background: "linear-gradient(269.95deg, #6C328B 0.04%, #F42074 71.48%, #EF2B4B 99.95%)",
        borderRadius:"4px"
    }
  
  });


const styles: any = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    accordin:{
        boxShadow: '0px 0px 16px 0px #BFBEBE59 !important',
        marginBottom:'10px',
        borderRadius:'12px',
        backgroud:'#fff',
        width: "100%"

    },
    contentContainer: {
        display:'flex',
        gap:'15px',
        width:'100%'
    },
    rewardImg:{
        width:'70px',
        height:'70px',
        borderRadius:'8px'
    },
    rewardName:{
        fontSize:'16px',
        fontWeight:700,
        color:'#1C1B1B',
        lineHeight:'22px',
        '&::first-letter':{
          textTransform: 'uppercase'
        }
    },
    rewardDate:{
        fontSize:'14px',
        fontWeight:500,
        lineHeight:'22px',
        margin:'10px 0'
    },
    rewardDueDate:{
        fontSize:'14px',
        fontWeight:500,
        color:'#E47004',
        lineHeight:'22px',
        margin:'10px 0'
    },
    norewardText:{
        fontSize:'18px',
        fontWeight:600,
        color:' #484646',
        lineHeight:'24px',
        padding:'20px'
    },
    contentCompletion:{
      fontSize:'20px',
      color:'#1C1B1B',
      fontWeight:600,
      lineHeight:'24px',
      marginBottom:'15px'
    },
    contentCompletionReceived:{
      fontSize:'20px',
      color:'#1C1B1B',
      fontWeight:600,
      lineHeight:'24px',
    },
    completionBox:{
      display:'flex',
      alignItems:'center',
      gap:'10px',
      background:'#D7FFE7',
      padding:'2px 7px',
      marginBottom:'15px'
    },
    overdueBox:{
      display:'flex',
      alignItems:'center',
      gap:'10px',
      background:'#FFF5F5',
      padding:'2px 7px',
      marginBottom:'15px'
    },
    roundGreenCheck:{
      width:'28px',
      height:'28px'
    },
    unstartedContentBox:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'start',
      padding:'0 10px 10px'
    },
    pregressBox:{
      display:'flex',
      gap:'5px',
      alignItems:'start',
    },
    unstartedBuletPoint:{
      fontSize:'8px',
      color:'#484646',
      margin:'7px'
    },
    listText:{
      fontSize:'16px',
      lineHeight:'22px',
      color:'#484646'
    },
    loginText:{
      fontSize:'20px',
      fontWeight:600,
      lineHeight:'24px',
      color:'#1C1B1B',
      padding:'10px 0'
    },
    descriptionDetails:{
      lineHeight:'22px',
      color:'#484646'
    },
    redeemUstartedButton:{
      background:'#E5E2E1',
      borderRadius:'88px',
      color:'#787776',
      width:'300px',
      height:'48px',
      boxShadow:'none',
      textTransform:'capitalize',
      fontSize:'16px',
      fontWeight:600,
      '&:hover':{
        boxShadow:'none',
        background:'#E5E2E1',
      }
    },
    redeemReceivedButton:{
      background:'#E47004',
      borderRadius:'88px',
      color:'#FFF',
      width:'300px',
      height:'48px',
      boxShadow:'none',
      textTransform:'capitalize',
      fontSize:'16px',
      fontWeight:600,
      '&:hover':{
        boxShadow:'none',
        background:'#E47004',
      }
    },
    redeemRedeemButton:{
      color:'#652786',
      fontSize:'16px',
      fontWeight:600,
      textTransform:'capitalize',
    },
    Hr:{
      border:'0.5px solid #E5E2E1',
      width:'90%'
    },
    redeemBox:{
      textAlign:'center',
      margin:'15px 0 5px'
    },
    progressText:{
      fontSize:'14px',
      lineHeight:'16px',
    },
    completedText:{
      fontSize:'14px',
      lineHeight:'16px',
      color:'#652786'
    },
    overdueText:{
      fontSize:'14px',
      lineHeight:'16px',
      color:'#BF0032'
    }
  });

interface ContentType1 {
  id:number
  name:string
  status:string
  color:string
}

interface NumberLessonType1 {
  total_lessons:number
  completed_lessons:number
  status:string
  reward_achievement_status:string
}

interface RewardAttibute1 {
    name:string
    icon:{
      id:number
      url:string
    }
    timeline:{
      tag:string
      color:string
    }[]
    progress_bar:number
    content:ContentType1[]
    redeemed:boolean
    unstarted:boolean
    completion:boolean
    description:string
    is_complete_content:boolean
    is_number_of_days_logged_in:boolean
    number_of_days_logged_in: { is_logged_in: boolean, number_of_days_logged_in: number, login_count: number, message: string}
    is_number_of_lessons_completed:boolean
    number_of_lessons_completed:NumberLessonType1
    is_number_of_courses_completed:boolean
    number_of_courses_completed: { total_courses: number, completed_courses:  number, status: string }
    is_number_of_days_logged_in_streak:boolean
    number_of_days_logged_in_streak: { is_logged_in: boolean, number_of_days_logged_in: number, login_count: number, message: string}
}
interface RewardDataType1 {
    id:string
    attributes:RewardAttibute1
}

interface Props extends WithStyles<typeof styles> {
    rewardData:RewardDataType1[]
    activeTab:string
}

interface State {
  expanded: string | false;
}

class RewardsFrame extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  render() {
    const { classes,rewardData,activeTab } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        {rewardData.length > 0 ?
        <>
        {rewardData.map(data =>
        <Accordion 
            expanded={expanded === data.id} onChange={this.handleChange(data.id)}
            className={classes.accordin}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color:'#1C1B1B'}}/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box className={classes.contentContainer}>
                <img src={data.attributes.icon.url} className={classes.rewardImg}/>
                <Box style={{width:'100%'}}>
                    <Typography className={classes.rewardName}>{data.attributes.name}</Typography>
                    <Typography className={classes.rewardDate} style={{color:data.attributes.timeline[0].color}}>{data.attributes.timeline[0].tag}</Typography>
                    <Box>
                    <StyledProgressBar variant="determinate" value={data.attributes.progress_bar * 100} />
                    </Box>
                </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{boxShadow:'none'}}>
            <Box style={{width:'100%'}}>
              {data.attributes.is_complete_content &&
              <>
              {data.attributes.content.some((item) => item.status === "Overdue") &&
              <Box className={classes.overdueBox}>
                <img src={roundUncheck} alt="un-check" className={classes.roundGreenCheck}/>
                <Typography className={classes.contentCompletionReceived}>Content completion</Typography>
              </Box>
              }
              {data.attributes.completion &&
              <Box className={classes.completionBox}>
                <img src={greenRoundCheck} alt="check" className={classes.roundGreenCheck}/>
                <Typography className={classes.contentCompletionReceived}>Content completion</Typography>
              </Box>
              }             
              {data.attributes.unstarted &&
              <Typography className={classes.contentCompletion}>Content completion</Typography>
              }
              {(!data.attributes.unstarted && !data.attributes.completion) &&
              <Typography className={classes.contentCompletion}>Content completion</Typography>
              }
              {data.attributes.content.map(item =>
                <Box className={classes.unstartedContentBox}>
                  <Box className={classes.pregressBox}>
                    {data.attributes.unstarted &&
                    <FiberManualRecordIcon className={classes.unstartedBuletPoint}/>
                    }
                    {item.status === "In progress" &&
                    <FiberManualRecordIcon className={classes.unstartedBuletPoint}/>
                    }
                  {item.status === "Completed" &&
                  <img src={checkMark} alt="check"/>
                  }
                  {item.status === "Overdue" &&
                  <img src={unCheckMark} alt="uncheck"/>
                  }
                  <Typography className={classes.listText}>{item.name}</Typography>
                  </Box>
                  <Typography className={classes.progressText} style={{color:item.color}}>{item.status}</Typography>
                </Box>
              )}
              </>
              }
              {data.attributes.is_number_of_days_logged_in &&
              <Typography className={classes.loginText}>{data.attributes.number_of_days_logged_in.message}</Typography>
              }
              {data.attributes.is_number_of_days_logged_in_streak &&
              <Typography className={classes.loginText}>{data.attributes.number_of_days_logged_in_streak.message}</Typography>
              }
              {
                data.attributes.is_number_of_courses_completed &&
                <>
                {data.attributes.number_of_courses_completed.status === "completed" &&
                  <Box className={classes.completionBox}>
                    <img src={greenRoundCheck} alt="check" className={classes.roundGreenCheck}/>
                    <Typography className={classes.contentCompletionReceived}>
                      {data.attributes.number_of_courses_completed.completed_courses}/{data.attributes.number_of_courses_completed.total_courses} courses completed</Typography>
                  </Box>
                }
                {data.attributes.number_of_courses_completed.status === "overdue" &&
                  <Box className={classes.overdueBox}>
                  <img src={roundUncheck} alt="un-check" className={classes.roundGreenCheck}/>
                  <Typography className={classes.contentCompletionReceived}>
                  {data.attributes.number_of_courses_completed.completed_courses}/{data.attributes.number_of_courses_completed.total_courses} courses completed
                  </Typography>
                </Box>
                }
                {(data.attributes.number_of_courses_completed.status === "in_progress" || data.attributes.number_of_courses_completed.status === "unstarted") &&
                  <Typography className={classes.loginText}>
                      {data.attributes.number_of_courses_completed.completed_courses}/{data.attributes.number_of_courses_completed.total_courses} courses completed
                  </Typography>
                }
                </>
              }
              {
                data.attributes.is_number_of_lessons_completed &&
                <>
                {data.attributes.number_of_lessons_completed.status === "completed" &&
                  <Box className={classes.completionBox}>
                    <img src={greenRoundCheck} alt="check" className={classes.roundGreenCheck}/>
                    <Typography className={classes.contentCompletionReceived}>
                      {data.attributes.number_of_lessons_completed.completed_lessons}/{data.attributes.number_of_lessons_completed.total_lessons} lessons completed
                    </Typography>
                  </Box>
                }
                {data.attributes.number_of_lessons_completed.status === "overdue" &&
                  <Box className={classes.overdueBox}>
                  <img src={roundUncheck} alt="un-check" className={classes.roundGreenCheck}/>
                  <Typography className={classes.contentCompletionReceived}>
                  {data.attributes.number_of_lessons_completed.completed_lessons}/{data.attributes.number_of_lessons_completed.total_lessons} lessons completed
                  </Typography>
                </Box>
                }
                {(data.attributes.number_of_lessons_completed.status === "in_progress" || data.attributes.number_of_lessons_completed.status === "unstarted") &&
                  <Typography className={classes.loginText}>
                      {data.attributes.number_of_lessons_completed.completed_lessons}/{data.attributes.number_of_lessons_completed.total_lessons} lessons completed
                  </Typography>
                }
                </>
              }
              {activeTab === "rewards" &&
              <Box style={{overflow:'auto'}}>
              <hr className={classes.Hr}/>
              <Typography className={classes.loginText}>For earning this reward you will receive:</Typography>
              <Typography className={classes.descriptionDetails}>{data.attributes.description}</Typography>
              </Box>
              }
              {
                data.attributes.redeemed &&
              <Box className={classes.redeemBox}>
              <Button 
                className={classes.redeemRedeemButton}
                startIcon={<img src={redeemCheck} alt="check"/>}
                >
                  Redeem
               </Button>
              </Box>
              }
            </Box>
          </AccordionDetails>
        </Accordion>
         )}
         </> :
         <div style={{textAlign:'center'}}>
            <img src={emptyReward} alt="no data"/>
            <Typography className={classes.norewardText}>No Data found</Typography>
        </div>}
      </div>
    );
  }
}

export default withStyles(styles)(RewardsFrame);
// Customizable Area End